var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex flex-col"},[_c('div',{staticClass:"overflow-hidden flex flex-col justify-center items-center flex-grow h-full"},[_c('div',{staticClass:"absolute flex flex-col justify-center items-center w-full top-8"},[(_vm.currentMatch)?_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-gray-500 font-light text-xs md:text-lg"},[_vm._v(_vm._s(_vm.formatDateTime(_vm.currentMatch.kickoffTime)))]),_c('h2',{staticClass:"text-2xl md:text-3xl"},[_vm._v(" "+_vm._s(_vm.currentMatch.teamHome.name)+" "),_c('span',{staticClass:"font-light text-gray-500"},[_vm._v("v.")]),_vm._v(" "+_vm._s(_vm.currentMatch.teamAway.name)+" ")]),_c('p',{staticClass:"text-gray-500 font-light text-xs md:text-lg"},[_vm._v(_vm._s(_vm.currentMatch.location))]),_c('div',{staticClass:"my-3 pill-pts py-1 px-3 text-white"},[_vm._v(" "+_vm._s(_vm.currentMatch.roundNumber + 2)+" points ")])]):_vm._e()]),_c('div',{staticClass:"flex flex-col items-center justify-end z-50"},[_c('transition',[(_vm.prediction.choice)?_c('PredictionSwiperStatus',{class:[
            'pointer-events-none transition',
            _vm.awaitingConfirmation
              ? 'opacity-100 scale-100'
              : 'opacity-70 scale-90',
          ],attrs:{"match":_vm.prediction.match,"choice":_vm.prediction.choice}}):_vm._e()],1)],1),_c('div',{staticClass:"w-full text-center flex flex-col justify-start items-center"},[(_vm.showConfirm)?_c('div',{staticClass:"contents"},[_c('h4',{staticClass:"text-xl mb-3 text-glow z-50"},[_vm._v(_vm._s(_vm.currentMatchHasPrediction ? 'Keep' : 'Confirm')+" your prediction?")]),_c('div',{staticClass:"flex justify-evenly w-full items-center z-50"},[_c('UndoButton',{on:{"click":_vm.undoChoice}}),_c('ConfirmButton',{on:{"click":_vm.submitPrediction}})],1)]):_vm._e()])]),_c('div',{staticClass:"h-full overflow-hidden flex flex-col justify-center items-center absolute top-1/2 -translate-y-1/2 w-full left-0"},_vm._l((_vm.matches),function(match,index){return _c('PredictionSwiperCard',{key:match.id,ref:"card",refInFor:true,attrs:{"active":match.id === _vm.currentMatch.id && !_vm.awaitingConfirmation,"match":match,"index":index},on:{"submit":_vm.confirmChoice},model:{value:(_vm.prediction),callback:function ($$v) {_vm.prediction=$$v},expression:"prediction"}})}),1),(!_vm.currentMatchHasPrediction)?_c('SwipeTutorial',{attrs:{"arrows":{
      left: `<small>Swipe left to predict</small><br><strong>${_vm.matches[0].teamHome.name} Wins</strong>`,
      right: `<small>Swipe right to predict</small><br><strong>${_vm.matches[0].teamAway.name} Wins</strong>`,
      down: _vm.matches[0].groupId ? `<small>Swipe down to predict</small><br><strong>Draw</strong>` : null,
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }